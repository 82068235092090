<template>
  <div class="waterPop"  :style="{ transform: popTips.transformStyle }" v-show="popTips.visible">
      <div class="waterTip">
        <div class="popLeft">
            <div class='el-icon-location-outline'></div>
            <p>水深</p>
            <p>潮位</p>
            <p>流速</p>
            <p>流向</p>
        </div>
        <div class="popRight">
            <p>{{lon.toFixed(7) || 'N/A'}}</p>
            <p>{{lat.toFixed(7) || 'N/A'}}</p>
            <p>{{ !isNaN(location.depth) ? location.depth.toFixed(2) : 'N/A'}} m</p>
            <p>{{ !isNaN(location.tdLevel) ? location.tdLevel.toFixed(2) : 'N/A'}} m</p>
            <p>{{ !isNaN(location.velo) ? location.velo.toFixed(2) : 'N/A'}} m/s</p>
            <p>{{ !isNaN(location.deri) ? location.deri.toFixed(1) : 'N/A'}} °</p>
        </div>
      </div>
      <div class="mouseLight"></div>
      <div class="mouse_point"></div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
export default {
  // 组件名称
  name: 'waterPop',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: { },
  // 组件状态值
  data() {
    return {
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false
      },
        lon:0,
        lat:0,
        location:{}
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  mounted() {
    mapApp.weatherMapActions.waterPopVueComponent = this
    console.log('加载水动力气泡')
  },
  destroyed() {
    mapApp.weatherMapActions.waterPopVueComponent = null
    console.log('销毁水动力气泡')
  },
  // 组件方法
  methods: {

  }
}
</script>

<style lang="scss" scoped>

    .waterPop{
      position: absolute;
      top: 80px;
      left: -90px;
      transform: translate(50%, 50%);
      background-color: rgba(7, 35, 61,0.6);
      color: #fff;
      font-size: 14px;
      box-sizing: border-box;
      border: 1px solid;
      border-color: #55769e;
      width: 150px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        line-height: 24px;
      }
      .waterTip{
        display: flex;
        .popLeft{
          display: flex;
          flex-direction: column;
          align-items: center;
          .el-icon-location-outline{
            padding: 6px;
            font-size: 35px;
          }
        }
      }
      .mouseLight{
        position: absolute;
        left: 160px;
        top: 85px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        animation: twinkling 2s infinite linear;
        text-align: center;
        cursor: pointer;
      }
      .mouse_point{
        position: absolute;
        left: 163px;
        top: 88px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #ff7266;
      }

}
@keyframes twinkling {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  35% {
    opacity: 0.8;
    transform: scale(1.0);
  }
  70% {
    opacity: 0.4;
    transform: scale(1.4);
  }
  100% {
    opacity: 0;
    transform: scale(1.8);
  }
}

</style>
