<template>
  <div class="pop_container" :style="{ transform: popTips.transformStyle }" v-show="popTips.visible">
    <div class="pop_base">
      <div class="box" @click="popExtend">
        <img :src="popStyle.iconUrl" />
      </div>
      <div class="line"></div>
    </div>
    <div class="pop_main" v-show="isPopExtendShow">
      <div class="pop_line">
        <div class="obliqueLine"></div>
        <div class="straightLine"></div>
      </div>
      <div class="pop_box">
        <div class="pop_title">
          <div><img :src="popStyle.iconUrl" /><span>固定航线-</span><span>F181航线</span></div>
          <div class="close" @click="popClose"><i class="el-icon-close"></i></div>
        </div>

        <div class="pop_content">
          <div class="pop_icon">
            <div class="outer">
              <div class="inside"></div>
              <div class="point">
                <img :src="popStyle.iconUrl" />
              </div>
            </div>
          </div>

          <div class="pop_detail">
            <div class="pop_item">
              <div id="name">名字：</div>
              <div id="content">{{ popData.name }}</div>
            </div>
            <div class="pop_item">
              <div id="name">位置：</div>
              <div id="content">{{ popData.msgData.markerName }}</div>
            </div>
            <div class="pop_item">
              <div id="name">流速</div>
              <!-- <div id="content">{{ popData.content.A }}</div> -->
              <div id="content">{{ popData.msgData.velocity }}m/s</div>
            </div>
            <div class="pop_item">
              <div id="name">流向：</div>
              <!-- <div id="content">{{ popData.content.B }}</div> -->
              <div id="content">{{ popData.msgData.direction }}°</div>
            </div>
            <div class="pop_item">
              <div id="name">潮位：</div>
              <!-- <div id="content">{{ popData.content.C }}</div> -->
              <div id="content">{{ popData.msgData.tidalLevel }}m</div>
            </div>
            <div class="pop_item">
              <div id="name">富余水深：</div>
              <!-- <div id="content">{{ popData.content.D }}</div> -->
              <div id="content">{{ popData.msgData.ukc }}m</div>
            </div>
          </div>
          <div class="pop_more"><i class="el-icon-caret-bottom"></i><span>查看更多</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
export default {
  // 组件名称
  name: 'FacilityPop',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: { popData: { type: Object } },
  // 组件状态值
  data () {
    return {
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false,
      },

      popStyle: {
        iconUrl: require('../../../assets/images/channel/航道航线.png')
      },

      isPopExtendShow: false
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  destroyed () {
    mapApp.channelMapActions.closeRoutePop()
  },

  // 组件方法
  methods: {
    popExtend () {
      this.isPopExtendShow = true
    },
    popClose () {
      this.isPopExtendShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_container {
  position: absolute;
  z-index: 10;

  // left: 50%;
  // top: 50%;
  .pop_base {
    position: relative;

    .box {
      position: absolute;
      top: -40px;
      left: -42px;
      transform: translateX(50%);
      width: 42px;
      height: 42px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #48b9cf;
      box-shadow: 0 0 2px 20px #208fa3a1 inset, 0 0 2px 1px #24616d;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: auto;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .line {
      width: 1px;
      height: 60px;
      background-color: #56defa;
    }
  }

  .pop_main {
    position: absolute;
    top: -190px;
    left: 30px;

    .pop_line {
      position: relative;
      display: flex;

      .obliqueLine {
        position: absolute;
        left: -22px;
        top: -1px;
        height: 150px;
        border-left: 2px solid #34c9ee;
        transform-origin: 0 100%;
        transform: rotate(20deg);
        background-color: #34c9ee;
        animation: scale-in-bl 0.3s ease;
        animation-fill-mode: forwards;
      }

      .straightLine {
        // margin-left: 15px;
        position: absolute;
        left: 30px;
        top: 7px;
        width: 40px;
        height: 2px;
        opacity: 0;
        background-color: #34c9ee;
        animation: scale-in-hor-left 0.2s ease 0.3s;
        animation-fill-mode: forwards;
      }
    }

    .pop_box {
      position: absolute;
      top: -25px;
      left: 70px;
      width: 400px;
      background-color: #03050eb0;
      box-sizing: border-box;
      border: 1px solid #1c899c;
      padding: 13px;
      animation: shutter-in-top 0.5s ease 0.5s forwards;
      opacity: 0;

      &::before {
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        width: 5px;
        height: 10px;
        background-color: #42deff;
      }

      &::after {
        content: '';
        position: absolute;
        right: -2px;
        top: -2px;
        width: 5px;
        height: 10px;
        background-color: #42deff;
      }

      .pop_title {
        color: #fff;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-image: linear-gradient(to right, #173d66, rgba(0, 0, 0, 0));
        padding: 5px;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 24px;
        vertical-align: middle;
        font-family: YouSheBiaoTiHei;

        img {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }

        span {
          letter-spacing: 0.5px;

          &:nth-child(3) {
            color: #42deff;
          }
        }

        .close {
          width: 22px;
          height: 22px;
          color: #27abc2;
          margin-right: 10px;
          box-sizing: border-box;
          border: 2px solid #1e92a7;
          text-align: center;
          line-height: 16px;
          cursor: pointer;
          pointer-events: auto;

          i {
            font-size: 12px;
            font-weight: bolder;
          }
        }
      }

      .pop_content {
        padding: 5px;
        display: flex;
        color: #fff;
        font-family: SiYuan;

        .pop_icon {
          padding-left: 10px;
          margin-top: 20px;

          .outer {
            position: relative;
            width: 72px;
            height: 72px;
            background-image: url('../../../assets/images/channel/circle.png');
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .point {
              width: 62px;
              height: 62px;
              background-image: url('../../../assets/images/channel/circle_point.png');
              background-size: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 36px;
                height: 36px;
                // margin-bottom: 4px;
              }
            }

            .inside {
              position: absolute;
              width: 72px;
              height: 72px;
              background-image: url('../../../assets/images/channel/circle1.png');
              background-size: 100%;
              background-repeat: no-repeat;
              display: flex;
              justify-content: center;
              align-items: center;
              animation: circle_rotate 5s infinite;
              animation-timing-function: linear;
            }
          }
        }

        .pop_detail {
          width: 260px;
          margin-bottom: 15px;
          display: grid;
          grid-template-columns: 50% 50%;

          .pop_item {
            margin: 0 0 10px 50px;

            #name {
              position: relative;
              font-size: 12px;

              &::before {
                content: '';
                position: absolute;
                left: -10px;
                top: 7px;
                width: 5px;
                height: 5px;
                background-color: #1fdefa;
              }
            }

            #content {
              font-size: 14px;
              color: #1fdefa;
            }
          }
        }

        .pop_more {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          border-bottom: 18px solid #275185;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          height: 0;
          width: 100px;
          text-align: center;
          line-height: 18px;
          cursor: pointer;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@keyframes flicker-circle {
  0% {
    opacity: 0.2;
  }

  7.14% {
    opacity: 1;
  }

  10% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes scale-in-bl {
  0% {
    transform: scale(0) rotate(20deg);
    transform-origin: 0% 100%;
    opacity: 1;
  }

  100% {
    transform: scale(1) rotate(20deg);
    transform-origin: 0% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-hor-left {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes shutter-in-top {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
</style>
