<template>
  <div class="channel_container">
    <div class="aside_left">
      <aside class="aside_btn">
        <div>
          <div class="main_btn" @click="mainBtnHandle(1)" :class="{ btn_active: btnActive === 1 }">
            <img src="../../assets/images/channel/航道航线.png" />航道航线
          </div>
          <div class="detail_btn" v-show="btnActive === 1">
            <div class="detail_item" @click="seaRouteBtn()">
              <div class="btn_radio" :class="{ btn_selected: isSeaRoute }"></div>
              <div>海上航线</div>
            </div>
            <div class="detail_item" @click="btnHandle(3)">
              <div class="btn_radio" :class="{ btn_selected: btnSelect === 3 }"></div>
              <div>海上船舶</div>
            </div>
            <div class="detail_item" @click="btnHandle(2)">
              <div class="btn_radio" :class="{ btn_selected: btnSelect === 2 }"></div>
              <div>海底地形</div>
            </div>

            <div class="detail_item" @click="naviDialogBth()">
              <div class="btn_radio" :class="{ btn_selected: isNaviDialog }"></div>
              <div>通航预测</div>
            </div>
          </div>
        </div>
        <div>
          <div class="main_btn" @click="mainBtnHandle(2)" :class="{ btn_active: btnActive === 2 }">
            <img src="../../assets/images/channel/助航设施.png" />助航设施
          </div>
          <div class="detail_btn" v-show="btnActive === 2">
            <div class="detail_item" @click="strandAnalyzeInit()">
              <div class="btn_radio" :class="{ btn_selected: isStrandFlag }"></div>
              <div>搁浅分析</div>
            </div>
          </div>
        </div>
        <div>
          <div class="main_btn" @click="mainBtnHandle(3)" :class="{ btn_active: btnActive === 3 }">
            <img src="../../assets/images/channel/气象监测.png" />水动力模拟
          </div>
          <div class="detail_btn detail_btn_3" v-show="btnActive === 3">
            <!-- <div class="detail_item" @click="btnHandle2(1)">
              <div class="btn_radio" :class="{ btn_selected: btnSelect2 === 1 }"></div>
              <div>风场</div>
            </div>
            <div class="detail_item" @click="btnHandle2(2)">
              <div class="btn_radio" :class="{ btn_selected: btnSelect2 === 2 }"></div>
              <div>能见度</div>
            </div>
            <div class="detail_item" @click="btnHandle2(3)">
              <div class="btn_radio" :class="{ btn_selected: btnSelect2 === 3 }"></div>
              <div>水动力</div>
            </div> -->
            <div class="detail_item water_power" @click="flowDialogBth('openDialog')">
              <div class="btn_radio " :class="{ btn_selected: isFlowDialog }"></div>
              <div>
                <div>水动力模拟</div>
                <div>和质点追踪</div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <!-- 风场滑动条 -->
    <div v-show="btnSelect2 === 1">
      <div class="play_btn">
        <div @click="windRun" v-show="!windPlay">
          <img src="../../assets/images/channel/播放.png" />
        </div>
        <div @click="sliderStop" v-show="windPlay">
          <img src="../../assets/images/channel/暂停.png" />
        </div>
      </div>
      <div class="wind">
        <div class="myTooltip" :style="windStyle" v-show="popWindData.id">
          <div class="tooltip_content">
            <div class="tooltip_item" v-show="popWindData.id">
              <div id="name">观测站ID：</div>
              <div id="content">
                <span>{{ popWindData.id }}</span>
              </div>
            </div>
            <div class="tooltip_item" v-show="popWindData.date">
              <div id="name">日期</div>
              <div id="content">
                <span>{{ popWindData.date }}</span>
              </div>
            </div>
            <div class="tooltip_item" v-show="popWindData.windSpeed">
              <div id="name">十分钟平均风速：</div>
              <div id="content">
                <span>{{ popWindData.windSpeed }}</span>
                <span>m/s</span>
              </div>
            </div>
            <div class="tooltip_item" v-show="popWindData.windDirection">
              <div id="name">十分钟平均风向：</div>
              <div id="content">
                <span>{{ popWindData.windDirection }}</span>
                <span>°</span>
              </div>
            </div>
          </div>
          <div class="tooltip_triangle"></div>
        </div>
        <el-slider v-model="windSliderValue" :step="1" :show-tooltip="false" :marks="windMarks" :min="0" :max="143"
          @input="windValueChange($event)"></el-slider>
      </div>
    </div>
    <!-- 能见度滑动条 -->
    <div v-show="btnSelect2 === 2">
      <div class="play_btn">
        <div @click="visRun" v-show="!visPlay">
          <img src="../../assets/images/channel/播放.png" />
        </div>
        <div @click="sliderStop" v-show="visPlay">
          <img src="../../assets/images/channel/暂停.png" />
        </div>
      </div>
      <div class="visibility">
        <div class="myTooltip" :style="visStyle" v-show="popVisData.id">
          <div class="tooltip_content">
            <div class="tooltip_item" v-show="popVisData.id">
              <div id="name">观测站ID：</div>
              <div id="content">
                <span>{{ popVisData.id }}</span>
              </div>
            </div>
            <div class="tooltip_item" v-show="popVisData.date">
              <div id="name">日期</div>
              <div id="content">
                <span>{{ popVisData.date }}</span>
              </div>
            </div>
            <div class="tooltip_item" v-show="popVisData.intensity">
              <div id="name">十分钟平均值</div>
              <div id="content">
                <span>{{ popVisData.intensity }}</span>
                <span>km</span>
              </div>
            </div>
          </div>
          <div class="tooltip_triangle"></div>
        </div>
        <el-slider v-model="visSliderValue" :step="1" :show-tooltip="false" :marks="visMarks" :min="0" :max="143"
          @input="visValueChange($event)"></el-slider>
      </div>
    </div>
    <!-- 水动力滑动条 -->
    <div class="water" v-show="btnSelect2 === 3">
      <el-slider v-model="waterSliderValue" :step="1" :format-tooltip="waterFormatTooltip" :marks="marks2" :min="0"
        :max="240" @change="switchWaterWaveData()"></el-slider>
    </div>
    <!-- 水动力当前位置信息 -->
    <div id="waterLocation"></div>

    <!-- 海上航线图例 -->
    <div v-show="isSeaRoute && btnSelect === 0 && btnActive === 1 && !isNaviDialog">
      <div class="searoute_chart">
        <div class="chart_item">
          <div class="chart_title"> <img src="../../assets/images/channel/乘潮窗口.png" alt=""> 主航道乘潮窗口期 </div>
          <li>当前乘潮窗口期：<span>{{ mainTide? mainTide.startTime + ' — ' + mainTide.endTime : '预报暂无乘潮窗口期' }}</span></li>
          <li>下个乘潮窗口期：<span>{{ mainTide? mainTide.next : '暂无' }}</span></li>
          <li>剩余可操作时间：<span>{{ mainTide? mainTide.hours : '——' }}</span></li>

        </div>
        <div class="chart_item">
          <div class="chart_title"> <img src="../../assets/images/channel/乘潮窗口.png" alt=""> 港池乘潮窗口期 </div>
          <li>当前乘潮窗口期：<span>{{ dockTide? dockTide.startTime + ' — ' + dockTide.endTime : '预报暂无乘潮窗口期' }}</span></li>
          <li>下个乘潮窗口期：<span>{{ dockTide? dockTide.next : '暂无' }}</span></li>
          <li>剩余可操作时间：<span>{{ dockTide? dockTide.hours : '——' }}</span></li>
        </div>

      </div>
      <div class="searoute_legend">
        <div class="legend_item">
          <!-- <div class="color"></div> -->
          <img src="../../assets/images/channel/sea_routes/hangdaozhongxinxian.png" />
          <div class="text">航道中心线</div>
        </div>
        <div class="legend_item">
          <!-- <div class="color"></div> -->
          <img src="../../assets/images/channel/sea_routes/hangdaofanwei.png" />
          <div class="text">航道范围</div>
        </div>
        <div class="legend_item">
          <!-- <div class="color"></div> -->
          <img src="../../assets/images/channel/sea_routes/lianjieshuiyu.png" />
          <div class="text">连接水域</div>
        </div>
        <!-- <div class="legend_item"> -->
          <!-- <div class="color"></div> -->
          <!-- <img src="../../assets/images/channel/sea_routes/huixuan.png" />
          <div class="text">回旋水域</div>
        </div> -->
        <div class="legend_item">
          <!-- <div class="color"></div> -->
          <img src="../../assets/images/channel/sea_routes/gangchi.png" />
          <div class="text">港池</div>
        </div>

      </div>

    </div>
    <div class="searoute_legend" v-if="btnSelect === 3">
      <div class="legend_item seaboat">
        <!-- <div class="color"></div> -->
        <img src="../../assets/images/channel/sailing.png" />
        <div class="text">航行中</div>
      </div>
      <div class="legend_item seaboat">
        <!-- <div class="color"></div> -->
        <img src="../../assets/images/channel/mooring.png" />
        <div class="text">停泊中</div>
      </div>
    </div>

    <!-- 水底地形气泡 -->
    <pop-seabed ref="popSeabedRef"></pop-seabed>
    <!-- 水底地形图例 -->
    <div class="seabedLegend" v-show="btnSelect === 2">

      <div class="legend_item">
        <div class="text special_text">-1.00 <span>m</span>
        </div>
      </div>
      <div class="legend_item" v-for="(item, index) in seabedColors" :key="index">
        <el-color-picker class="color" v-model="terrainColorArray[seabedColors.length - index - 1][1]"
          @change="changeTerrainColor"></el-color-picker>
        <div class="text">
          {{ seabedDepth[index].toFixed(2) }} <span>m</span>
        </div>
      </div>
    </div>
    <!-- 导航设施 -->
    <div v-if="popNavData">
      <div v-for="(item, index) in popNavData" :key="index">
        <pop-wharf ref="popNavRef" :popData="item"></pop-wharf>
      </div>
    </div>
    <!-- 流场弹窗 -->
    <el-dialog id="flowField" ref="flowField" :visible.sync='isFlowDialog' :append-to-body="true" width="80%"
      @close='flowDialogBth("closeDialog")' top="0" :show-close="false" center>
      <div class="el-icon-full-screen dialogBth" @click="flowDialogBth('fullDialog')"></div>
      <div class="el-icon-close dialogBth" @click="flowDialogBth('closeDialog')"></div>
      <iframe src="https://hk.tkshks.com:8021/?key=883d7c2098e0cd10a555b99e7087c109" frameborder="0" name="flowField"
        width="100%" height="100%"> </iframe>
    </el-dialog>
    <!-- 通航预测弹窗 -->
    <el-dialog id="navigation" ref="navigation" :show-close="false" :visible.sync='isNaviDialog' :append-to-body="true"
      :close-on-click-modal="false" @closed='closeNavi' width="70%" top="0%" center>
      <div class="navi_title">主航道通航预测</div>
      <div class="el-icon-close dialogBth" @click="closeNavi"></div>
      <div class="left_navigation">
        <ul>
          <li>通航数据输入</li>
          <li class="navi_text">
            <p>节：(海里/h)</p>
            <el-input-number v-model="naviKn" :precision="1" :step="0.1" :max="6.0" :min="0.1"></el-input-number>
          </li>
          <li class="navi_text">
            <p>吃水：(m)</p>
            <el-input-number v-model="naviDraft" :precision="1" :step="0.1" :max="5.0" :min="0"></el-input-number>
          </li>
          <li class="navi_text">
            <p>出发时间：</p>
            <el-date-picker v-model="naviTime" type="datetime" placeholder="请选择未来24小时之内" :picker-options="pickerOptions"
              format="yyyy-MM-dd  HH:mm:ss" value-format="yyyy-MM-dd  HH:mm:ss">
            </el-date-picker>
          </li>
          <li>
            <el-button type="danger" plain :style="{ 'opacity': naviFail ? 10 : 0 }">时间范围不合法(请选择未来24小时之内)</el-button>
          </li>
          <li>
            <el-button type="primary" @click="initNaviChart">航道预测</el-button>
          </li>

        </ul>
      </div>
      <div class="right_navigation" ref="naviChart"></div>
    </el-dialog>
    <!-- 搁浅分析窗口 -->
    <div class="strand_window_bg" v-if="isStrandFlag">
      <div class="strand_window">
        <div><span>经度：&nbsp;&nbsp;&nbsp;</span>
          <el-input style="width: 180px;" size="mini" v-model="strandData.x" placeholder="点击地图获取经度" type="number">
          </el-input>
          <el-tooltip class="item" effect="dark" content="点击地图拾取经纬度" placement="top">
            <div :class="{ obtain: true, obtain_class: obtainFlag }" id="obtainLonLat" @click="obtainLonLat">
              <img src="../../assets/images/channel/拾取经纬度.png" alt="">
            </div>
          </el-tooltip>

        </div>
        <div><span>纬度：&nbsp;&nbsp;&nbsp;</span>
          <el-input type="number" size="mini" v-model="strandData.y" placeholder="点击地图获取纬度"></el-input>
        </div>
        <div><span>搁浅时间：</span>
          <el-date-picker v-model="strandData.time" type="datetime" placeholder="点击获取日期时间" size="mini"
            format="yyyy-MM-dd  HH:mm:ss" value-format="yyyy-MM-dd  HH:mm:ss" @change="strandData.message = ''">
          </el-date-picker>

        </div>
        <div class="strand_float">
          <el-button type="primary" @click="strandRequest">查询</el-button>
          <div class="strand_warn" :style="{ opacity: strandData.message ? 1 : 0 }">{{ strandData.message }}</div>
        </div>
        <div class="strand_chart">
          <basic-chart :styles-data="strandOptions.stylesData" :series-data="strandOptions.seriesData" />
        </div>
        <div>距离脱险水位时间：<span class="strand_span">{{ strandData.wait }}</span> </div>
        <div>窗口期持续时间：<span class="strand_span">{{ strandData.hours }}</span></div>
      </div>
    </div>
    <!-- 搁浅分析鼠标气泡 -->
    <div id="strand_tips">
      <div><span class="color_block"></span> <span>经度：</span><b>{{ strandData.x }}</b></div>
      <div><span class="color_block"></span> <span>纬度：</span><b>{{ strandData.y }}</b></div>
    </div>

    <pop-ship></pop-ship>
    <pop-water></pop-water>
    <pop-forecast></pop-forecast>
    <!-- 海上航线气泡 -->
    <div v-if="popRouteData.length">
      <div v-for="(item, index) in popRouteData" :key="index">
        <pop-route ref="popRouteRef" :popData="item" v-show="popRouteData.length"></pop-route>
      </div>
    </div>

  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import popSeabed from '../map/popMarker/pop-seabed.vue'
import popWharf from '../map/popMarker/pop-wharf.vue'
import popShip from '../map/popMarker/pop-ship.vue'
import popForecast from '../map/popMarker/pop-forecast.vue'
import popWater from '../map/popMarker/pop-water.vue'
import popRoute from '../map/popMarker/pop-route.vue'

import request from '../../utils/request'
import * as echarts from 'echarts'
import { mercator2lonLat } from '../../map/utils/tools'
import basicChart from '@/components/echarts/basic-chart.vue'


export default {
  name: 'Channel',
  components: { popSeabed, popWharf, popShip, popForecast, popWater, basicChart, popRoute },
  watch: {
    windSliderValue: function (val) {
      if (val > 143) {
        this.sliderStop()
        this.windSliderValue = 0
      }
    },
    visSliderValue: function (val) {
      if (val > 143) {
        this.sliderStop()
        this.visSliderValue = 0
      }
    },
    isStrandFlag: function (val) {
      if (!val) {
        this.closeLonLat()
      }
    }
  },
  data () {
    return {
      popNavData: [],
      popWindData: {
        id: null,
        date: null,
        windSpeed: null,
        windDirection: null
      },
      popVisData: {
        id: null,
        date: null,
        intensity: null
      },
      asideBtnAry: [
        {
          id: 1,
          name: '航道航线',
          imgUrl: require('../../assets/images/wharf/监控.png')
        },
        {
          id: 2,
          name: '助航设施',
          imgUrl: require('../../assets/images/wharf/泊位.png')
        },
        {
          id: 3,
          name: '气象检测',
          imgUrl: require('../../assets/images/wharf/设施.png')
        }
      ],
      // 主按钮
      btnActive: 0,
      // 航道监测子按钮
      btnSelect: 0,
      // 气象监测子按钮
      btnSelect2: 0,

      // 水动力当前数据时刻
      waterWaveCurrTime: 0,

      // 海水深度范围
      // seabedDepth: [0.65, 0.3, -0.05, -0.40, -0.75, -1.10, -1.45, -1.80, -2.15, -2.50], // 原始
      seabedDepth: [-0.65, -0.3, 0.05, 0.40, 0.75, 1.10, 1.45, 1.80, 2.15, 2.50],
      // 海水深度色值
      seabedColors: [],
      //单独拆分海上航道按钮
      isSeaRoute: false,
      windPlay: false,
      windSliderValue: 0,
      windStyle: {
        marginLeft: '',
        marginBottom: '10px'
      },
      visPlay: false,
      visSliderValue: 0,
      visStyle: {
        marginLeft: '',
        marginBottom: '10px'
      },

      sliderSign: null,

      waterSliderValue: 0,
      windMarks: {},
      visMarks: {},
      marks2: {
        0: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周一 11-01')
        },
        24: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周二 11-02')
        },
        48: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周三 11-03')
        },
        72: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周四 11-04')
        },
        96: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周五 11-05')
        },
        120: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周六 11-06')
        },
        144: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周日 11-07')
        },
        168: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周一 11-08')
        },
        192: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周二 11-09')
        },
        216: {
          style: {
            color: '#fff'
          },
          label: this.$createElement('strong', '周三 11-10')
        }
      },

      isShowSeaHydraulics: false,

      routePoints: null,
      windField: null,
      visibility: null,
      isFlowDialog: false,//打开流场窗口标识
      isNaviDialog: false, //打开航道预测窗口标识
      naviChart: null,//航道预测的图表实例
      naviArr: [],
      naviKn: 1.6,
      naviDraft: 3.0,
      naviTime: null,//三个通航预测参数
      naviFail: false,//参数不正确提示
      naviRAF: null,
      pickerOptions: {
        shortcuts: [{
          text: '今天此时',//相对此刻多一分钟，才符合参数要求
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 60 * 1000)
            picker.$emit('pick', date);
          }
        }, {
          text: '一小时后',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000);
            picker.$emit('pick', date);
          }
        },
        {
          text: '十小时后',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 10);
            picker.$emit('pick', date);
          }
        }, {
          text: '明天此时',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }]
      },
      //用户选择的海底地形颜色 从下到上排序
      terrainColorArray: [[0.0, '#163EC4'], [0.1, '#3071A9'], [0.2, '#3A8BA9'], [0.3, '#45A9B0'], [0.4, '#98FFAA'], [0.5, '#A4FF8F'], [0.6, '#CCFE76'], [0.8, '#E3EA6D'], [0.9, '#EFB48C'], [1.0, '#EE9898']],

      // terrainColorArray:[],
      // fackTerrainColorArray:[],
      //海上航线的乘潮数据呈现
      tideFlag: false,
      mainTide: null,
      dockTide: null,
      //搁浅分析窗口打开标识
      isStrandFlag: false,
      strandData: {
        x: 118.120760,
        y: 39.175493,
        time: '',
        wait: '',
        hours: '',
        message: ''
      },
      //搁浅分析图表配置
      strandOptions: {
        stylesData: {},
        seriesData: []
      },
      //拾取经纬度标识
      obtainFlag: false,
      popRouteData: [],
    }
  },
  created () {
    this.getWindFieldData()
    this.getVisibilityData()
    this.getChannelJSON()
    mapApp.channelVueComponent = this
    if (mapApp.channelMapActions) {
      mapApp.channelMapActions.resetView()
    } else {
      mapApp.addEventListener('mapAppInitComplete', () => {
        mapApp.channelMapActions.resetView()
        // 回填部分缓存内容
        mapApp.backfillDataDevicePopData();
      })
    }
    this.isSeaRoute = mapApp.homeVueComponent.btnShowpopRoute

  },
  mounted () {
    this.getPopSeabedBinding()
    if (this.isSeaRoute) {
      this.$nextTick(() => {
        this.getRouteChart()
        this.getRoutePointsData();

        //如果开启着海上航线，默认打开航道航线菜单和航道气泡
        this.btnActive = 1

      })
    }

  },
  beforeDestroy () {
    this.clearMapEffect()
    this.closeLonLat()

  },
  computed: {
    naviVelo: function () {
      return (this.naviKn * 1852 / 1000).toFixed(1)
    }
  },
  methods: {
    // 获取航道点数据
    async getRoutePointsData () {
      try {
        if (!this.routePoints) {
          const { data } = await request('fn/channelPoint')
          console.log("航道点数据", data)
          let channelPoint = data.data.channelPoints
          for (let key in channelPoint) {
            this.popRouteData.push({
              id: key,
              name: "1号航道",
              msgData: {
                markerName: "记录点" + key.substring(key.length - 1, key.length),
                direction: channelPoint[key].direction, //流向
                tidalLevel: channelPoint[key].tidalLevel, // 潮位
                ukc: channelPoint[key].ukc, // 富余水深
                velocity: channelPoint[key].velocity.toFixed(3) * 1, // 流速
                waterDepth: channelPoint[key].waterDepth, // 水深【暂不显示】
              }
            })
          }
          if (this.popRouteData.length == 0) {
            console.error('获取航道点数据失败!');
          } else {
            this.$nextTick(() => {
              this.getPopRouteBinding()
              mapApp.channelMapActions.createRoutePointsPopMarkers()
            })
          }
        }
      } catch (err) {
        console.log('获取数据失败！', err)
      }
    },
    // 获取风场数据
    async getWindFieldData () {
      try {
        if (!this.windField) {
          const { data } = await request('fn/wind', 148)
          this.windField = data.data.wind
          this.sliderMarksSet()
        }
      } catch (err) {
        console.log('获取数据失败！')
      }
    },
    // 获取能见度数据
    async getVisibilityData () {
      try {
        if (!this.visibility) {
          const { data } = await request('fn/visi', 148)
          this.visibility = data.data.visibility
        }
      } catch (err) {
        console.log('获取数据失败！')
      }
    },
    // 获取页面气泡数据
    async getChannelJSON () {
      try {
        const { data } = await this.$http.get('/json/channel.json')
        this.popNavData = data.popNavData
      } catch (err) {
        console.log('获取数据失败！')
      }
    },
    // 滑动条节点文字设置
    sliderMarksSet () {
      // 风场 marks
      const len = this.windField.length
      let tempDate = this.windField[0]['DATA_TIME']
      const tempIndex = tempDate.indexOf('T')
      let tempObj = {}
      let tempAry = []
      for (let i = len - 144; i < len; i++) {
        tempAry.push(this.windField[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6))
      }
      for (let i = 0; i < 144; i += 12) {
        tempObj[i] = {}
        tempObj[i].style = {
          color: '#fff'
        }
        tempObj[i].label = this.$createElement('strong', tempAry[i])
      }
      this.windMarks = tempObj

      // 能见度 marks
      const len2 = this.visibility.length
      let tempDate2 = this.visibility[0]['DATA_TIME']
      const tempIndex2 = tempDate2.indexOf('T')
      let tempObj2 = {}
      let tempAry2 = []
      for (let i = len2 - 144; i < len2; i++) {
        tempAry2.push(this.visibility[i]['DATA_TIME'].substring(tempIndex2 + 1, tempIndex2 + 6))
      }
      for (let i = 0; i < 144; i += 12) {
        tempObj2[i] = {}
        tempObj2[i].style = {
          color: '#fff'
        }
        tempObj2[i].label = this.$createElement('strong', tempAry2[i])
      }
      this.visMarks = tempObj2
    },
    // 风场滑块移动
    windValueChange (data) {
      if (data === 0) return
      // 滑块位移距离
      let temp = `${(data / 143) * 100 + 0.4}%`
      this.windStyle.marginLeft = temp
      const len = this.windField.length

      let index = len - 144 + data

      const tempIndex = this.windField[index]['DATA_TIME'].indexOf('T')
      let tempDate = this.windField[index]['DATA_TIME']
      tempDate = tempDate.substring(0, tempIndex) + ' ' + tempDate.substring(tempIndex + 1, tempIndex + 6)

      this.popWindData.date = tempDate
      this.popWindData.id = this.windField[index]['STATION_ID']
      this.popWindData.windSpeed = this.windField[index]['AVG_SPEED_10M']
      this.popWindData.windDirection = this.windField[index]['AVG_DIRECTION_10M']

      let angle = Number(this.windField[index]['AVG_DIRECTION_10M'])
      mapApp.weatherMapActions.windMarker.rotateTo(angle)
    },
    // 能见度滑块移动
    visValueChange (data) {
      if (data === 0) return
      let temp = `${(data / 143) * 100 + 0.4}%`
      this.visStyle.marginLeft = temp

      const len = this.visibility.length
      let index = len - 144 + data

      const tempIndex = this.visibility[index]['DATA_TIME'].indexOf('T')
      let tempDate = this.visibility[index]['DATA_TIME']
      tempDate = tempDate.substring(0, tempIndex) + ' ' + tempDate.substring(tempIndex + 1, tempIndex + 6)

      this.popVisData.id = this.visibility[index]['STATION_ID']
      this.popVisData.date = tempDate
      this.popVisData.intensity = this.visibility[index]['AVG_10M']

      let alpha = (Number(this.visibility[index]['AVG_10M']) / 20) * 0.5 + 0.05
      mapApp.weatherMapActions.fogMarker.setAlpha(alpha) // 0.2 ~ 1
    },
    //
    waterFormatTooltip (value) {
      return (value % 24) + ':00'
    },
    // 清除滑动条相关数据
    clearSliderData () {
      this.windSliderValue = 0
      this.visSliderValue = 0
      this.popWindData.id = null
      this.popVisData.id = null
      this.sliderStop()
    },
    // 主按钮
    mainBtnHandle (num) {
      this.clearMapEffect()
      this.clearSliderData()
      this.btnSelect = 0
      this.btnSelect2 = 0
      this.isStrandFlag = false

      if (this.btnActive === num) {
        this.btnActive = 0
        mapApp.weatherMapActions.resetView()
      } else {
        this.btnActive = num
      }
      if (this.btnActive === 2) {
        this.getPopNavBinding()
        this.showPopNav()
      } else if (this.btnActive === 3) {
        mapApp.weatherMapActions.resetView()
        this.$emit('mainBtnClose', false)
        // mapApp.channelMapActions.flySpecificPlace()
      } else if (this.btnActive === 1) {
        mapApp.channelMapActions.setRoutesMenuView()
        this.$emit('mainBtnClose', true)
      }
    },
    // 航道航线子按钮
    btnHandle (num) {
      this.clearMapEffect()
      this.btnSelect2 = null
      if (this.btnSelect === num) {
        this.btnSelect = 0
        mapApp.weatherMapActions.resetView()
      } else {
        this.btnSelect = num
        if (this.btnSelect === 2) {
          this.showSeabed()
          // this.getSeabedLegend()
          this.getSeabedColors()
        } else if (this.btnSelect === 3) {
          mapApp.channelMapActions.setAllBoatsView();
        }
      }
    },
    //抽离海上航道按钮，单独配置
    seaRouteBtn () {
      if (this.isSeaRoute) {
        if (this.btnSelect !== 3) {
          mapApp.weatherMapActions.resetView()
        }
        this.isSeaRoute = false
        mapApp.homeVueComponent.btnShowpopRoute = false
        // this.clearMapEffect()//清除所有特效标注层
        mapApp.channelMapActions.clearPolyLayer()//清除航道标注层
        this.tideFlag = false
      } else {
        this.isSeaRoute = true
        mapApp.homeVueComponent.btnShowpopRoute = true
        this.showSeaRoute()
        this.getRouteChart()
      }
    },
    // 气象监测子按钮
    btnHandle2 (num) {
      this.clearMapEffect()
      this.clearSliderData()
      this.btnSelect = null
      if (this.btnSelect2 === num) {
        this.btnSelect2 = null
        mapApp.weatherMapActions.hideAllWeatherMarker()
        mapApp.weatherMapActions.closeWaterWave()
        if (num === 3) {
          setTimeout(function () {
            mapApp.weatherMapActions.resetView()
          }, 500)
        }
      } else {
        if (this.btnSelect2 === 3) {
          setTimeout(function () {
            mapApp.weatherMapActions.resetView()
          }, 500)
        }
        this.btnSelect2 = num
        mapApp.weatherMapActions.hideAllWeatherMarker()
        if (this.btnSelect2 === 1) {
          console.log('风')
          mapApp.weatherMapActions.showWindMarker()
        }
        if (this.btnSelect2 === 2) {
          console.log('能见度')
          mapApp.weatherMapActions.showFogMarker()
        }
        if (this.btnSelect2 === 3) {
          console.log('水动力')
          mapApp.weatherMapActions.initWaterWave()
          mapApp.weatherMapActions.waterClickSwitch(true)
        }
      }
    },
    // 开始播放
    windRun () {
      this.windPlay = true
      this.sliderSign = setInterval(() => {
        this.windSliderValue++
      }, 100)
    },

    visRun () {
      this.visPlay = true
      this.sliderSign = setInterval(() => {
        this.visSliderValue++
      }, 100)
    },
    // 滑块移动停止
    sliderStop () {
      this.windPlay = false
      this.visPlay = false
      clearInterval(this.sliderSign)
      this.sliderSign = null
    },

    // 海上航线
    showSeaRoute () {
      this.getPopRouteBinding()
      if (this.btnSelect !== 3) {
        mapApp.channelMapActions.setBoatRoutesView()// 移动到海上航线视角  
      }
      mapApp.channelMapActions.showBoatRouteEffect()//创建主航道相关
      // mapApp.channelMapActions.showConvolutePop()//显示回旋水域
      // mapApp.channelMapActions.showRoutePopMarker()
    },
    // 海底地形
    showSeabed () {
      mapApp.channelMapActions.setSeabedView()
      // 可自定义海底深度色卡组[0~1为梯度值，对应梯度的颜色值]，海底默认在主题包中使用了一种颜色梯度配置
      mapApp.channelMapActions.showSeabedEffect({ isCustomColor: true, colorArray: this.terrainColorArray })
      mapApp.channelMapActions.showSeabedPopMarker()
    },
    // 航线节点气泡
    showSeaNav () {
      mapApp.channelMapActions.showNavPopMarker()
    },
    // 清除页面地图效果
    clearMapEffect () {
      mapApp.channelMapActions.clearAllEffect()
      mapApp.weatherMapActions.closeWaterWave()
      mapApp.weatherMapActions.clearWeatherMarker()
    },
    // 航线气泡绑定
    getPopRouteBinding () {
      console.log(this.$refs.popRouteRef)
      if (!this.$refs.popRouteRef) return
      for (let i = 0; i < this.$refs.popRouteRef.length; i++) {
        mapApp.channelMapActions.routePopVueComponents[i] = this.$refs.popRouteRef[i]
      }
    },
    // 海底气泡绑定
    getPopSeabedBinding () {
      mapApp.channelMapActions.seabedPopVueComponent = this.$refs.popSeabedRef
    },
    // 海底效果图例渐变
    getSeabedLegend () {
      let tempColorAry = esmap.ESEffectTool.models.getTerrainGradientConfig({ name: 'sea_ground' })
      let bgcString = 'linear-gradient(to right'
      // background-image: linear-gradient(to right, red, blue);
      for (let i = tempColorAry.length - 1; i >= 0; i--) {
        //  tempColorAry[i][1]
        bgcString = bgcString + ',' + tempColorAry[i][1]
      }
      bgcString = bgcString + ')'
      document.querySelector('.legendColor').style.backgroundImage = bgcString
    },
    getSeabedColors () {
      let tempColorAry = esmap.ESEffectTool.models.getTerrainGradientConfig({ name: 'sea_ground' })
      this.seabedColors = []
      for (let i = tempColorAry.length - 1; i >= 0; i--) {
        this.seabedColors.push(tempColorAry[i])
      }
    },

    // 导航气泡绑定
    getPopNavBinding () {
      for (let i = 0; i < this.$refs.popNavRef.length; i++) {
        mapApp.channelMapActions.navPopVueComponents[i] = this.$refs.popNavRef[i]
      }
    },
    // 气象气泡绑定
    getPopWeaBinding () {
      for (let i = 0; i < this.$refs.popWeaRef.length; i++) {
        mapApp.channelMapActions.weaPopVueComponents[i] = this.$refs.popWeaRef[i]
      }
    },
    // 地图气泡显示
    showPopRoute () {
      mapApp.channelMapActions.showRoutePopMarker()
    },
    showPopNav () {
      mapApp.channelMapActions.showNavPopMarker()
    },
    // 气象监测
    showWaterWave () {
      // mapApp.weatherMapActions.resetView();
      if (this.isShowWaterWave) {
        mapApp.weatherMapActions.closeWaterWave()
        mapApp.channelMapActions.resetView()
      } else {
        mapApp.weatherMapActions.initWaterWave()
        this.isShowWaterWave = true
      }
    },
    // 切换至水动力数据加载
    switchWaterWaveData () {
      if (this.btnSelect2 !== 3) return
      let start = 172800
      let step = 3600
      let result = start + step * this.waterWaveCurrTime
      console.log(result)
      if (mapApp.weatherMapActions) {
        mapApp.weatherMapActions.loadWaterWaveData(result)
      }
    },
    //流场窗口操作
    flowDialogBth (action) {
      if (action === 'openDialog') {
        this.isFlowDialog = true
        return
      } else if (action === 'closeDialog') {
        this.isFlowDialog = false
      }

      const dialogDom = this.$refs['flowField'].$el.childNodes[0]

      if (action === 'fullDialog' && dialogDom.className.indexOf('full_screen') > 0 || action === 'closeDialog') {
        dialogDom.className = dialogDom.className.replace(' full_screen', '')
      } else {
        dialogDom.className += ' full_screen'
      }

    },
    //点击通航预测窗口操作
    naviDialogBth () {
      this.isNaviDialog = true
      this.$nextTick(() => {
        const time = new Date()
        time.setMinutes(time.getMinutes() + 1);//在当前时间加一分钟
        this.naviTime = this.dateFormat(time, 'yyyy-MM-dd  hh:mm:ss')
        this.initNaviChart()
      })
    },
    //创建航道预测图表
    async initNaviChart (event) {
      const dom = this.$refs['naviChart']
      let terrainArr1 = [], terrainArr2 = [], datumArr = [], tideArr = [], xArr = [], fillArr = []
      if (!this.naviChart) {
        this.naviChart = echarts.init(dom, null, {
          renderer: 'canvas',
          useDirtyRect: false
        });
        window.addEventListener('resize', this.naviChart.resize);
      }


      if (this.naviTime) {
        var { data } = await request('fn/routeFore', {
          velo: this.naviVelo,//船速
          draft: this.naviDraft,//船舶吃水
          startTime: this.naviTime ? this.naviTime.replace('  ', 'T') + '.000' : null//从主航道入口出发的时间
        })
      }

      if (data && data.data && data.data.milePoints) {
        this.naviFail = false //时间错误弹窗标识
        const height = 0 //统一基面高度
        const fill = height - 2 //作为填充值 
        this.naviArr = data.data.milePoints
        const length = this.naviArr.length - 1 //为了x轴的值从大到小排序
        this.naviArr.forEach((e, i) => {
          xArr.push(((length - i) / 10).toFixed(1))//x轴值
          //水下地形高或低于基面值分别存储
          if (height - e.depth > 0) {
            terrainArr1.push(height - e.depth)
            terrainArr2.push(0)
          } else {
            terrainArr2.push(height - e.depth)
            terrainArr1.push(0)
          }
          datumArr.push(height)//基面值
          tideArr.push(e.depth < 0 ? e.tdlevel + e.depth : e.tdlevel)//潮位值
          fillArr.push(e.depth > 0 ? fill + e.depth : fill)//填充水下地形的负值

          e.arriveTime = e.arriveTime.split('T').join('  ').replace('.000', '')

        })
      } else {
        this.naviFail = true
        return
      }

      var option = {
        animationEasing: 'linear',
        title: {
          text: '船舶移动预测',
          textStyle: {
            fontSize: 16,
            color: '#fff',
            fontFamily: 'YouSheBiaoTiHei',
            fontWeight: 100
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          formatter: (params, e) => {
            const index = params[0].dataIndex
            return (
              `
                        经度：${this.naviArr[index].coords.lon}
                        <br/>
                        纬度：${this.naviArr[index].coords.lat}
                        <br/>
                        潮位：${this.naviArr[index].tdlevel} m
                        <br/>
                        水深：${this.naviArr[index].depth} m
                        <br/>
                        到达时间：${this.naviArr[index].arriveTime}
                        <br/>
                        吃水深度：${this.naviDraft} m
                        <br/>
                        安全情况：${this.naviArr[index].isSafe ? '安全' : '不安全'}
                        `
            );
          },
        },
        legend: {
          type: 'plain',
          show: true,
          orient: 'vertical',
          right: 0,
          bottom: '14%',
          itemGap: 68,
          data: ['不安全点位', '船舶路线', '潮位', '基面', '水下地形'],
          textStyle: {
            color: '#fff',
            textBorderColor: '#000',
            textBorderWidth: 1,
            fontFamily: 'YouSheBiaoTiHei',
            fontWeight: 100,
            fontSize: 14
          }
        },
        grid: {
          left: '4%',
          right: '15%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            offset: 5,
            name: "里程数(单位:km)",
            boundaryGap: false,
            data: xArr,
            nameLocation: "middle",
            nameTextStyle: {
              padding: [15, 0]
            }
          }

        ],
        yAxis: [
          {
            type: 'value',
            name: '高度(单位:m)',
            scale: true
          }
        ],
        series: [
          {
            name: '水下地形',
            type: 'line',
            stack: 'Total',
            color: '#eaba71',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: '#eaba71'
            },
            data: terrainArr1
          },
          {
            name: '低于基面水下地形',
            type: 'line',
            stack: 'Total',
            color: 'rgb(77, 119, 255)',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(77, 119, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(77, 119, 255)'
                }
              ])
            },
            data: terrainArr2
          },

          {
            name: '潮位',
            type: 'line',
            color: '#00DDFF',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 221, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(77, 119, 255)'
                }
              ])
            },
            data: tideArr
          },
          {
            name: '基面',
            type: 'line',
            // stack: 'Total',
            color: '#f2f2f2',
            smooth: true,
            lineStyle: {
              width: 2,
              type: 'dotted'
            },
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: datumArr
          }, {
            name: '填充',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#eaba71'
                },
                {
                  offset: 1,
                  color: '#f6e4d6'
                }
              ])
            },
            data: fillArr
          },

          {
            name: '船舶路线',
            type: 'line',
            // stack: 'Total',
            color: '#89c588',
            smooth: true,
            lineStyle: {
              width: 3
            },
            showSymbol: false,
            markPoint: {
              label: {
                show: false
              },
              symbolRotate: 5,
              data: [{
                type: 'max',
                valueIndex: 0,
                symbol: `image://${require('../../assets/images/channel/货船.png')}`,
                symbolSize: 80,
                symbolOffset: [0, '-10%']
              }],
              symbolKeepAspect: false,
              animation: false,
            },
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '不安全点位',
            type: 'line',
            // stack: 'Total',
            color: 'red',
            smooth: true,
            lineStyle: {
              width: 3
            },
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(198, 30, 62,0.3)'
                },
                {
                  offset: 1,
                  color: 'rgba(198, 30, 62,0.3)'
                }
              ])
            },
            data: []
          },


          {
            //拉高图表的高度
            name: '',
            type: 'line',
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            data: [6]
          },
        ]
      };

      this.naviChart.setOption(option);
      this.naviChart.resize()
      this.moveNavi()//在图表加载船舶运行动画

    },
    moveNavi () {
      if (!this.naviArr.length) return
      const isSafeArr = [], boatArr = []
      const height = 0//统一基面高度
      let i = 0
      this.naviRAF = null

      this.naviRAF = () => {
        if (i >= this.naviArr.length) return
        const e = this.naviArr[i]
        isSafeArr.push(e.isSafe ? '' : height + e.tdlevel)//不安全点位
        boatArr.push(height + e.tdlevel)//船舶值
        let option = {
          series: [
            {
              name: '不安全点位',
              data: isSafeArr
            },
            {
              name: '船舶路线',
              data: boatArr,
              markPoint: i == (this.naviArr.length - 1) ? { data: [{ symbol: '' }] } : {},
            }
          ]
        }
        this.naviChart.setOption(option);
        i++
        setTimeout(() => {
          window.requestAnimationFrame(this.naviRAF)
        }, 20);
      }
      this.naviRAF()


    },
    closeNavi () {
      this.isNaviDialog = false
      if (this.naviChart) {
        this.naviChart.dispose()
        this.naviChart = null
      }
      this.naviTime = null
      this.naviFail = false
      this.naviArr = []
    },
    //把new Date时间转换成任意格式,this.dateFormat(new Date(),'yyyy-MM-dd  hh:mm:ss')
    dateFormat (date, fmt) {
      var o = {
        "M+": date.getMonth() + 1,  // 月份 
        "d+": date.getDate(),  // 日 
        "h+": date.getHours(),  // 小时 
        "m+": date.getMinutes(),  // 分 
        "s+": date.getSeconds(),  // 秒 
        "q+": Math.floor((date.getMonth() + 3) / 3),  // 季度 
        "S": date.getMilliseconds()  // 毫秒 
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }

      return fmt;
    },
    //用户改变海底地形颜色
    changeTerrainColor (e, i) {
      mapApp.channelMapActions.showSeabedEffect({ isCustomColor: true, colorArray: this.terrainColorArray })
    },
    //获取海上航线图表接口信息
    async getRouteChart () {
      if (!this.tideFlag && this.isSeaRoute && this.btnSelect === 0) {
        try {
          const { data } = await request('fn/tidalLv', 24)
          const list = data.winList
          this.tideFlag = true
          if (list.mainChannel.length) {
            this.mainTide = {
              startTime: list.mainChannel[0].startTime.substring(0, 19).replace('T', ' '),
              endTime: list.mainChannel[0].endTime.substring(0, 19).replace('T', ' '),
            }
            this.mainTide = this.compareTime(this.mainTide)
          }
          if (list.trWater.length) {
            this.dockTide = {
              startTime: list.trWater[0].startTime.substring(0, 19).replace('T', ' '),
              endTime: list.trWater[0].endTime.substring(0, 19).replace('T', ' '),
            }
            this.dockTide = this.compareTime(this.dockTide)
          }
        } catch (err) {
          console.log('获取数据失败！', err)
        }
      }
    },
    compareTime (tide) {
      const nowTime = this.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss')
      if (tide.startTime < nowTime && nowTime < tide.endTime) {
        tide.next = '正处于乘潮窗口期'
        tide.hours = this.twoTimeInterval(nowTime, tide.endTime)
      } else if (nowTime < tide.startTime) {
        tide.next = this.twoTimeInterval(nowTime, tide.startTime)
        tide.hours = '——'
      }
      return {
        ...tide
      }
    },
    //对比两个时间的差值
    twoTimeInterval (startTime, endTime) {
      let d1 = startTime.replace(/\-/g, "/");
      let date1 = new Date(d1);
      // 结束时间
      let d2 = endTime.replace(/\-/g, "/");
      let date2 = new Date(d2);
      // 时间相差秒数
      let dateDiff = date2.getTime() - date1.getTime();
      // 计算出相差天数
      let days = Math.floor(dateDiff / (24 * 3600 * 1000));
      // 计算出小时数
      let residue1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
      let hours = Math.floor(residue1 / (3600 * 1000));
      // 计算相差分钟数
      let residue2 = residue1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
      let minutes = Math.floor(residue2 / (60 * 1000));
      // 计算相差秒数
      let residue3 = residue2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
      let seconds = Math.round(residue3 / 1000);
      let returnVal =
        ((days == 0) ? "" : days + "天") +
        ((hours == 0) ? "" : hours + "小时") +
        ((minutes == 0) ? "" : minutes + "分钟")
      // + ((seconds == 0) ? "" : seconds+"秒"); 
      return returnVal;
    },
    //搁浅分析窗口初始化事件
    strandAnalyzeInit () {
      Object.assign(this.strandData, { x: 118.120760, y: 39.175493 })
      if (this.isStrandFlag) {
        this.isStrandFlag = false
        this.closeLonLat()
        this.strandOptions = { stylesData: {}, seriesData: [] }
      } else if (!this.isStrandFlag) {
        this.isStrandFlag = true
        this.obtainFlag = true
        this.strandClick()
        this.strandRequest()
      }
    },
    //获取搁浅点位
    strandClick (e) {
      if (this.isStrandFlag && this.obtainFlag) {
        this.closeLonLat(e)
        if (e && e.hitCoord) {
          const xy = mercator2lonLat(e.hitCoord.x, e.hitCoord.y)
          this.strandData.x = xy[0].toFixed(6)
          this.strandData.y = xy[1].toFixed(6)
        }
        this.strandData.message = ''
        const time = new Date()
        const minutes = time.getMinutes() % 10
        if (minutes !== 0) {
          time.setMinutes(time.getMinutes() + (10 - minutes));
        } else {
          time.setMinutes(time.getMinutes() + 10);
        }
        time.setSeconds(0);
        this.strandData.time = this.dateFormat(time, 'yyyy-MM-dd  hh:mm:ss')
      }
    },
    //获取搁浅接口数据
    async strandRequest () {
      try {
        const { data } = await request('fn/strandAnalysis', { lon: this.strandData.x, lat: this.strandData.y, strandTime: this.strandData.time })
        const dt = data.data
        if (typeof dt.curTideLevel === 'number') {
          this.strandData.message = ''
          this.strandData.wait = dt.time2wait > 0 ? dt.time2wait.toFixed(1) + 'h' : '0h'
          this.strandData.hours = dt.window[0] ? dt.window[0].hours.toFixed(1) + 'h' : '0h'
          var xArr = [], tdLevelArr = [], veloArr = [], direArr = []
          dt.tideLevelData.forEach((e, i) => {
            xArr.push(e.DATA_TIME.slice(11, 16))
            tdLevelArr.push(e.tdLevel)
            veloArr.push(dt.tideCurData[i].velo)
            direArr.push(dt.tideCurData[i].dire)
          })
          this.strandOptions = {
            stylesData: {
              animationDuration: 3000,
              grid: {
                left: "0%",
                right: "4%",
                top: "12%",
                bottom: "12%",
                containLabel: true
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  label: {
                    backgroundColor: '#6a7985'
                  }
                },
                formatter: (params) => {
                  const index = params[0].dataIndex
                  return (
                    `
                              时间：${params[0].name}
                              <br/>
                              潮位高度：${params[0].value}m
                              <br/>
                              流速：${veloArr[index]}m/s
                              <br/>
                              流向：${direArr[index]}°
                              `
                  );
                },
              },
              xAxis: {
                type: "category",
                data: xArr,
                boundaryGap: false,
                axisLabel: {
                  color: "#fff",
                  fontSize: 10,
                  interval: "auto"
                }
              },
              yAxis: {
                type: "value",
                name: "(m)",
                nameTextStyle: {
                  color: "#ffffff",
                  align: "right",
                  fontSize: 10
                },
                min: 0,
                max: 5,
                interval: 1,
                axisLabel: {
                  color: "#fff"
                },
                splitLine: {
                  lineStyle: {
                    type: "dashed",
                    color: "#29506C",
                    opacity: 0.5
                  }
                }
              },
              dataZoom: [
                {
                  type: "inside",
                  start: 0,
                  end: 100
                },
                {
                  height: 20,
                  bottom: 6,
                  start: 0,
                  end: 100
                }
              ]
            },
            seriesData: [
              {
                name: "潮位信息",
                type: "line",
                data: tdLevelArr,
                smooth: true,
                showSymbol: false,
                animation: true,
                animationDurationUpdate: 2000,
                animationEasingUpdate: 'quadraticIn',
                lineStyle: {
                  color: "#429ffe"
                },
                areaStyle: {
                  color: {
                    colorStops: [
                      {
                        offset: 0,
                        color: "#429ffe"
                      },
                      {
                        offset: 1,
                        color: "transparent"
                      }
                    ],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    type: "linear",
                    global: false
                  }
                }
              }]
          }

        } else {
          this.strandData.message = data.message
        }
      } catch (error) {
        console.log(error)
      }
    },
    obtainLonLat () {
      if (!this.obtainFlag) {
        this.obtainFlag = true
        const appDom = document.getElementById('map-container')
        const strandTips = document.getElementById('strand_tips')
        appDom.className += ' cursor_class'
        strandTips.style.display = 'none'
      }
    },
    //搁浅分析取消鼠标样式
    closeLonLat (e) {
      //如果有经纬度则提示小弹窗
      const strandTips = document.getElementById('strand_tips')
      if (e && e.hitCoord && strandTips) {
        strandTips.style.left = window.event.screenX + 10 + 'px'
        strandTips.style.top = window.event.screenY - 80 + 'px'
        strandTips.style.display = 'block'
      }
      if (!this.obtainFlag && strandTips) {
        strandTips.style.display = 'none'
      }
      this.obtainFlag = false
      const appDom = document.getElementById('map-container')
      appDom.className = appDom.className.replace(' cursor_class', '')

    },
  }
}
</script>

<style lang="scss" scoped>
.channel_container {
  pointer-events: none;

  .aside_left {
    position: absolute;
    height: 100%;
    width: 400px;
    color: #fff;
    background-image: linear-gradient(to right, rgb(3, 12, 30), rgb(3, 10, 24, 0.6) 50%, rgba(0, 0, 0, 0));
    z-index: 99;

    .aside_btn {
      position: relative;
      top: 40%;
      font-size: 18px;
      font-family: YouSheBiaoTiHei;
      box-sizing: border-box;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      animation: enter_left 1s;

      &>div {
        margin-bottom: 20px;
        pointer-events: auto;
      }

      .main_btn {
        position: relative;
        width: 120px;
        height: 46px;
        border: 1px solid #16429d;
        border-top-left-radius: 24px;
        border-bottom-right-radius: 24px;
        background-color: #15428a;
        line-height: 46px;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        img {
          width: 18px;
          margin: 0 6px 6px 0;
        }
      }

      .btn_active {
        background-color: #15428a;
        box-shadow: 0 0 10px 3px #4ab1ec inset;
      }

      .detail_btn {
        position: relative;
        top: -15px;
        width: 120px;
        box-sizing: border-box;
        padding-top: 15px;
        font-size: 16px;
        background-color: #10284a;
        z-index: 1;

        .detail_item {
          width: 120px;
          height: 38px;
          line-height: 38px;
          display: flex;
          /* padding-left: 25px; */
          align-items: center;
          cursor: pointer;
          justify-content: space-evenly;

          .btn_radio {
            width: 12px;
            height: 12px;
            margin-right: 6px;
            box-sizing: border-box;
            box-shadow: 0 0 3px rgb(150, 145, 145);
            border: 1px solid #fff;
          }
        }

        .water_power {
          height: 60px;

          &>div:last-child {
            line-height: 22px;
          }
        }
      }

      .detail_btn_3 {
        text-align: center;

        .detail_item>div:last-of-type {
          width: 75px;
        }
      }
    }
  }

  // 滑块
  .wind,
  .visibility,
  .water {
    position: absolute;
    bottom: 100px;
    left: 55%;
    transform: translateX(-50%);
    width: 1200px;
    z-index: 9;
    pointer-events: auto;
  }

  // 海上航线图例
  .searoute_chart {
    width: 400px;
    position: absolute;
    top: 120px;
    right: 50px;
    color: white;
    font-size: 16px;
    font-family: SiYuan;


    .chart_item {
      width: 100%;
      background-color: #03050e83;
      border-radius: 2%;
      padding: 5px 10px;
      margin-top: 20px;
      position: relative;
      border: 1px solid #059bff8a;

      .chart_title {
        display: flex;
        align-items: center;
        height: 28px;
        font-family: YouSheBiaoTiHei;
        background-image: linear-gradient(to right, #173d66, rgba(0, 0, 0, 0));

        img {
          width: 13px;
          height: 13px;
          margin: 0 10px;
        }
      }

      li {
        margin: 15px 5px;
        font-size: 14px;
      }

      span {
        color: #1fdefa;
      }
    }
  }

  .searoute_legend {
    position: absolute;
    bottom: 100px;
    right: 50px;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #10284a67;

    .legend_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #fff;
      width: 120px;
    }

    .seaboat {
      width: 80px;
      padding-right: 30px;
    }
  }

  // 海底地形图例
  .seabedLegend {

    position: absolute;
    bottom: 100px;
    right: 50px;
    padding: 20px;
    padding-right: 30px;
    border-radius: 10px;
    background-color: #10284a67;
    pointer-events: painted;

    .legend_item {
      position: relative;
      width: 75px;
      display: flex;
      align-items: center;
      color: #fff;

      .color {
        width: 45px;
        height: 25px;
      }

      .text {
        position: absolute;
        color: #5cf4ff;
        right: -18px;
        // verticl-align: bottom;
        font-size: 12px;
        margin-top: 28px;

        span {
          display: inline-block;
          transform: scale(0.6);
          color: #fff;
        }
      }

      .special_text {
        top: -34px;
      }

      ::v-deep .el-color-picker__trigger {
        padding: 0;
        border: 0;
        height: 30px;
      }
    }
  }
}

.btn_selected {
  background-color: #33b2e1;
}

.play_btn {
  position: absolute;
  bottom: 105px;
  transform: translateX(-570px);
  left: 50%;
  width: 32px;
  height: 32px;
  background-color: #409eff;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;

  img {
    width: 26px;
    height: 26px;
  }
}

.myTooltip {
  position: relative;
  transform: translateX(-50%);
  width: 350px;
  height: 100px;
  color: #fff;
  background-color: #03050eb0;
  border-radius: 10px;
  font-family: WenQuanDengKuanWeiMiHei;

  .tooltip_content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 10px 30px;

    .tooltip_item {
      position: relative;
      font-size: 12px;
      padding: 5px 5px;
      box-sizing: border-box;
      flex: 1;

      &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: -4px;
        width: 5px;
        height: 5px;
        background-color: #76bbe2;
      }

      span {
        &:first-child {
          font-size: 14px;
          color: #65d0f7;
        }
      }
    }
  }

  .tooltip_triangle {
    position: relative;
    left: 49%;
    top: -5px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #03050eb0;
  }
}

#flowField {
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep .el-dialog {
    background-color: #0a3263;
    margin: 0;
    top: 0;
    height: 80vh;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }

  ::v-deep .full_screen {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }

  .el-icon-full-screen {
    right: 50px;
    font-size: 18px;
  }

  .el-icon-close {
    right: 20px;
    font-size: 20px;
  }

}


.dialogBth {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px;
  color: #909399;
  cursor: pointer;

  &:hover {
    color: #409eff;
  }
}

//预测通航
#navigation {
  display: flex;
  align-items: center;
  font-family: YouSheBiaoTiHei;

  ::v-deep .el-dialog {
    top: 0;
    background-color: #040e1e7c;

    .el-dialog__body {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 30px;

      .navi_title {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%);
        font-size: 22px;
        color: white;
      }

      .left_navigation {
        color: white;
        height: 350px;

        .navi_text {
          font-family: SiYuan;
          font-size: 14px;

          input {
            color: #1fdefa;
            background-color: #03050e00;
          }

          .el-input-number__decrease,
          .el-input-number__increase {
            background-color: #40a0ff00;
            color: white;
          }
        }

        li {
          font-size: 16px;

          &:first-of-type {
            font-size: 18px;
            margin-bottom: 20px;
            text-align: center;
          }

          &>p:first-of-type {
            margin: 5px 0;
          }

          &>div:last-of-type {
            width: 100% !important;
          }

          &:last-of-type {
            text-align: center;
            margin-top: 20px;
          }

          .el-button--danger {
            width: 100%;
            padding: 5px 0;
            font-size: 14px !important;
          }

          .el-button {
            font-family: YouSheBiaoTiHei;
            font-size: 18px;
          }
        }

        ::v-deep .el-button--text {
          display: none;
        }
      }

      .right_navigation {
        width: 80% !important;
        height: 65vh !important;
      }
    }
  }
}

//搁浅分析窗口
.strand_window_bg {
  height: 100%;
  width: 400px;
  background-image: linear-gradient(to left, #030c1e, rgba(3, 10, 24, 0.6) 70%, rgba(0, 0, 0, 0));
  position: absolute;
  right: 0;
  animation: shutter-in-right 1.2s both;

  .strand_window {
    height: 480px;
    width: 330px;
    padding: 20px;
    position: absolute;
    top: 150px;
    right: 0;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    // background-color: #040e1e7c;
    font-family: YouSheBiaoTiHei;
    color: white;
    pointer-events: painted;

    &>div:first-of-type {
      display: flex;
    }

    .el-input {
      width: 220px;

      ::v-deep input {
        padding-right: 0;
      }
    }

    .obtain {
      display: inline-block;
      width: 35px;
      height: 28px;
      padding: 0;
      margin-left: 5px;
      background-color: white;
      border-radius: 5px;

      &:hover {
        background-color: #c8e1fb;
      }

      img {
        width: 20px;
        height: 20px;
        transform: translate(-50%);
        position: relative;
        left: 50%;
        top: 10%;
      }

    }

    .strand_chart {
      height: 220px;
      width: 100%;
    }

    .strand_span {
      color: #409eff;
    }

    .strand_float {

      .el-button,
      .strand_warn {
        font-size: 18px;
        margin-left: 75px;
        height: 40px;
        width: 220px;
        line-height: 0;
        display: block;
      }

      .el-button--primary {
        font-family: YouSheBiaoTiHei;
        margin-top: 7px;
      }

      .strand_warn {
        height: 24px;
        line-height: 22px;
        text-align: center;
        font-size: 12px;
        background-color: #fef0f0;
        color: #f56c6c;
        border: 1px solid #fbc4c4;
        border-radius: 5px;
        font-family: Microsoft YaHei;
        box-sizing: border-box;
      }
    }

    .obtain_class {
      background-color: #c8e1fb !important;
    }
  }
}

#strand_tips {
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  padding: 8px 10px;
  color: #575757;
  border-radius: 5px;
  animation: fade-in-fade-out 4s both;
  display: none;

  .color_block {
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: #66b2ff;
    border-radius: 50%;
  }
}



@keyframes shutter-in-right {
  from {
    transform: rotateY(-180deg);
    transform-origin: right;
    opacity: 0;
  }

  to {
    transform: rotateY(0deg);
    transform-origin: right;
    opacity: 1;
  }
}

@keyframes fade-in-fade-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
