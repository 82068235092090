<template>
  <div class="pop_container" :style="{ transform: popTips.transformStyle }" v-show="popTips.visible && display">
    <div class="pop_base">
      <div class="box">
        <div class="pop_title">
          <div class="title_left">
            <img src="../../../assets/images/channel/定位.png" />
            <div>
              经度：<span>{{ longitude }}</span>
            </div>
            <div>
              纬度：<span>{{ latitude }}</span>
            </div>
          </div>
          <div class="close" @click="popClose"><i class="el-icon-close"></i></div>
        </div>

        <div class="pop_content">
          <div class="pop_icon">
            <div class="outer">
              <div class="inside"></div>
              <div class="point">
                <img src="../../../assets/images/channel/seabed.png" />
              </div>
            </div>
          </div>
          <div>
            <div class="message" v-if="message"> {{ message }}</div>
            <div class="pop_detail">
              <div class="pop_item">
                <div id="name">实时水深：</div>
                <div id="content">
                  <span> {{ depth }}</span>m
                </div>
              </div>
              <div class="pop_item">
                <div id="name">水深：</div>
                <div id="content">
                  <span> {{ waterDepth }}</span>m
                </div>
              </div>
              <!-- <div class="pop_item">
                <div id="name">回淤：</div>
                <div id="content">
                  <span>XXX</span>
                </div>
              </div> -->
            </div>
          </div>

        </div>
      </div>
      <div class="line"></div>
      <div class="circle"></div>
    </div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
export default {
  // 组件名称
  name: 'SeabedPop',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: { popData: { type: Object } },
  // 组件状态值
  data () {
    return {
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false
      },
      display: false,
      depth: '', // 水深数据带潮位
      waterDepth: '', // 水深数据不带潮位
      longitude: '',
      latitude: '',
      message: ''
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  destroyed () {
    this.display = false
    mapApp.channelMapActions.closeSeabedPop()
  },
  // 组件方法
  methods: {
    popExtend () {
      this.isPopExtendShow = true
    },
    popClose () {
      this.display = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_container {
  position: absolute;

  .pop_base {
    position: relative;
    width: 100%;

    .box {
      position: relative;
      top: -50px;
      width: 360px;
      // transform: translateX(50%);
      padding: 13px;
      box-sizing: border-box;
      border: 1px solid #1c899c;
      background-color: #03050eb0;
      color: #fff;
      font-size: 12px;
      white-space: nowrap;

      .pop_title {
        color: #fff;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-image: linear-gradient(to right, #173d66, rgba(0, 0, 0, 0));
        padding: 5px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        vertical-align: middle;
        font-family: YouSheBiaoTiHei;

        .title_left {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }

          div {
            margin-left: 5px;
          }
        }

        .close {
          width: 20px;
          height: 20px;
          color: #27abc2;
          margin-right: 10px;
          box-sizing: border-box;
          border: 2px solid #1e92a7;
          text-align: center;
          line-height: 16px;
          cursor: pointer;
          pointer-events: auto;

          i {
            font-size: 12px;
            font-weight: bolder;
          }
        }
      }

      .pop_content {
        display: flex;
        justify-content: space-around;

        .message {
          position: absolute;
          top: 50px;
          left: auto;
          margin-left: 15px;

          &::before {
            content: '';
            position: absolute;
            left: -10px;
            top: 7px;
            width: 5px;
            height: 5px;
            background-color: #1fdefa;
          }
        }

        .pop_icon {
          padding-left: 10px;

          .outer {
            position: relative;
            width: 72px;
            height: 72px;
            background-image: url('../../../assets/images/channel/circle.png');
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .point {
              width: 62px;
              height: 62px;
              background-image: url('../../../assets/images/channel/circle_point.png');
              background-size: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 42px;
                height: 26px;
                margin-bottom: 4px;
              }
            }

            .inside {
              position: absolute;
              width: 72px;
              height: 72px;
              background-image: url('../../../assets/images/channel/circle1.png');
              background-size: 100%;
              background-repeat: no-repeat;
              display: flex;
              justify-content: center;
              align-items: center;
              animation: circle_rotate 5s infinite;
              animation-timing-function: linear;
            }
          }
        }



        .pop_detail {
          display: flex;
          font-family: SiYuan;

          .pop_item {
            margin: 15px;

            #name {
              position: relative;
              font-size: 12px;

              &::before {
                content: '';
                position: absolute;
                left: -10px;
                top: 7px;
                width: 5px;
                height: 5px;
                background-color: #1fdefa;
              }
            }

            #content {
              width: 40px;
            }
          }
        }
      }

      span {
        font-size: 14px;
        color: #04d9ff;
      }
    }

    .line {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 93px;
      width: 2px;
      height: 55px;
      background-color: #1fdefa;
      background-image: linear-gradient(to bottom, #03050e, #1fdefa);
    }
  }
}

@keyframes flicker-circle {
  0% {
    opacity: 0.2;
  }

  7.14% {
    opacity: 1;
  }

  10% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes scale-in-bl {
  0% {
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}
</style>
